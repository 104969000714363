"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("card", {
    staticClass: "form-new-service-pages"
  }, [_c("form", {
    attrs: {
      id: "customerDataForm"
    },
    on: {
      submit: _vm.checkForm
    }
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      label: "Nome do serviço",
      placeholder: "Informe o nome do serviço",
      error: _vm.$v.form.name.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.name, "$error", $event);
      },
      input: function input(val) {
        return _vm.toUpper(val, "name");
      }
    },
    model: {
      value: _vm.$v.form.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.name, "$model", $$v);
      },
      expression: "$v.form.name.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "field-type-service"
  }, [_c("app-select", {
    attrs: {
      label: "Tipo de serviço",
      placeholder: "Selecione o tipo de serviço",
      error: _vm.$v.form.digitalAssetType.$error,
      items: _vm.listServiceTypes,
      option: "_id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.digitalAssetType, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.digitalAssetType.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.digitalAssetType, "$model", $$v);
      },
      expression: "$v.form.digitalAssetType.$model"
    }
  }), _vm._v(" "), _c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewTypeService
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      error: _vm.$v.form.organizationId.$error,
      label: "Provedor do serviço",
      placeholder: "Selecione o provedor do serviço",
      items: _vm.listAllClients,
      option: "_id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.organizationId, "$error", $event);
      }
    },
    model: {
      value: _vm.clientUser,
      callback: function callback($$v) {
        _vm.clientUser = $$v;
      },
      expression: "clientUser"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_vm.listBusinessUnits.length ? _c("app-select", {
    attrs: {
      error: _vm.$v.form.businessUnitId.$error,
      label: "Área de negócio",
      placeholder: "Área de negócio",
      items: _vm.listBusinessUnits,
      option: "_id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.businessUnitId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.businessUnitId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.businessUnitId, "$model", $$v);
      },
      expression: "$v.form.businessUnitId.$model"
    }
  }) : _vm._e()], 1)]), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Descrição do serviço",
      placeholder: "Descreva brevemente",
      error: _vm.$v.form.description.$error,
      type: "textarea",
      max: "1500"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.description, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.description.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.description, "$model", $$v);
      },
      expression: "$v.form.description.$model"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      label: "SLA",
      placeholder: "15 ms",
      mask: ["###### ms"],
      error: _vm.$v.form.sla.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.sla, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.sla.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.sla, "$model", $$v);
      },
      expression: "$v.form.sla.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      label: "Telefone do suporte",
      placeholder: "+55 (00) 0 0000-0000",
      mask: ["+## (##) # ####-####", "+## (##) ####-####"],
      error: _vm.$v.form.phone.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.phone, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.phone.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.phone, "$model", $$v);
      },
      expression: "$v.form.phone.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      label: "E-mail do suporte",
      placeholder: "suporte@provedor.com",
      error: _vm.$v.form.email.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.email, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.email.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.email, "$model", $$v);
      },
      expression: "$v.form.email.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Webhook",
      placeholder: "Webhook do serviço",
      error: _vm.$v.form.webhook.$error,
      type: "password"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.webhook, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.webhook.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.webhook, "$model", $$v);
      },
      expression: "$v.form.webhook.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "URI de documentação",
      placeholder: "URI de documentação",
      error: _vm.$v.form.urlDocumentation.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.urlDocumentation, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.urlDocumentation.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.urlDocumentation, "$model", $$v);
      },
      expression: "$v.form.urlDocumentation.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Serviço de Integração",
      placeholder: "http://servico.interacao.internal",
      error: _vm.$v.form.serviceIntegration.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.serviceIntegration, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.serviceIntegration.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.serviceIntegration, "$model", $$v);
      },
      expression: "$v.form.serviceIntegration.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    attrs: {
      label: "Alias do serviço"
    }
  }, [_c("b-taginput", {
    attrs: {
      ellipsis: "",
      icon: "label",
      placeholder: "Informe um ou mais alias",
      "aria-close-label": "Delete this alias"
    },
    model: {
      value: _vm.$v.form.nicknames.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.nicknames, "$model", $$v);
      },
      expression: "$v.form.nicknames.$model"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    attrs: {
      label: "Alias do webhook do serviço "
    }
  }, [_c("b-taginput", {
    attrs: {
      ellipsis: "",
      icon: "label",
      placeholder: "Informe um ou mais alias",
      "aria-close-label": "Apagar os alias"
    },
    model: {
      value: _vm.$v.form.nicknamesWebhook.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.nicknamesWebhook, "$model", $$v);
      },
      expression: "$v.form.nicknamesWebhook.$model"
    }
  })], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    attrs: {
      label: "Report ID Analítico"
    }
  }, [_c("app-input", {
    attrs: {
      type: "password",
      placeholder: "Informe o Report ID do analítico",
      error: _vm.$v.form.reportId.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.reportId, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.reportId.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.reportId, "$model", $$v);
      },
      expression: "$v.form.reportId.$model"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    attrs: {
      label: "CTID Analítico "
    }
  }, [_c("app-input", {
    attrs: {
      type: "password",
      placeholder: "Informe o CTID do analítico",
      error: _vm.$v.form.ctid.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.ctid, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.ctid.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.ctid, "$model", $$v);
      },
      expression: "$v.form.ctid.$model"
    }
  })], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    class: _vm.$v.form.logo.$error ? "is-danger" : "",
    attrs: {
      label: "Logomarca"
    }
  }, [_c("b-upload", {
    attrs: {
      accept: "image/*",
      "drag-drop": ""
    },
    model: {
      value: _vm.fileLogo,
      callback: function callback($$v) {
        _vm.fileLogo = $$v;
      },
      expression: "fileLogo"
    }
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("b-icon", {
    attrs: {
      icon: "file-document",
      size: "is-large"
    }
  }), _vm._v(" "), !_vm.fileLogo ? _c("p", [_vm._v("Busque ou arraste o arquivo de imagem aqui")]) : _c("p", [_vm._v(_vm._s(_vm.fileLogo.name || "Arquivo selecionado"))])], 1)]), _vm._v(" "), _vm.logoValid && !_vm.fileLogo ? _c("img", {
    attrs: {
      src: _vm.form.logo,
      alt: "Logotipo"
    }
  }) : _vm._e()])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    class: _vm.$v.form.swagger.$error ? "is-danger" : "",
    attrs: {
      label: "Arquivo de Swagger"
    }
  }, [_c("b-upload", {
    attrs: {
      "drag-drop": "",
      accept: ".json"
    },
    model: {
      value: _vm.fileSwagger,
      callback: function callback($$v) {
        _vm.fileSwagger = $$v;
      },
      expression: "fileSwagger"
    }
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("b-icon", {
    attrs: {
      icon: "file-document",
      size: "is-large"
    }
  }), _vm._v(" "), !_vm.fileSwagger ? _c("p", [_vm._v("Busque ou arraste o arquivo .json aqui")]) : _c("p", [_vm._v(_vm._s(_vm.fileSwagger.name || "Arquivo selecionado"))])], 1)])])], 1)], 1)]), _vm._v(" "), _vm.idService ? _c("b-field", {
    attrs: {
      label: "Range de custo"
    }
  }) : _vm._e(), _vm._v(" "), _vm.idService ? _c("div", {
    staticClass: "list-rates-requests"
  }, [_c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewLineCost
    }
  }), _vm._v(" "), _vm._l(_vm.listRangesCost, function (item, idx) {
    return _c("b-field", {
      key: idx,
      attrs: {
        grouped: ""
      }
    }, [_c("app-input", {
      attrs: {
        expanded: "",
        label: "De",
        mask: ["#", "##", "###", "####", "#####", "######", "#######", "########", "#########", "##########", "###########", "############"],
        placeholder: "0",
        disabled: ""
      },
      model: {
        value: item.initialConsumption,
        callback: function callback($$v) {
          _vm.$set(item, "initialConsumption", $$v);
        },
        expression: "item.initialConsumption"
      }
    }), _vm._v(" "), _c("app-input", {
      attrs: {
        expanded: "",
        label: "Até",
        mask: ["#", "##", "###", "####", "#####", "######", "#######", "########", "#########", "##########", "###########", "############"],
        placeholder: "0"
      },
      model: {
        value: item.finalConsumption,
        callback: function callback($$v) {
          _vm.$set(item, "finalConsumption", $$v);
        },
        expression: "item.finalConsumption"
      }
    }), _vm._v(" "), _c("app-input", {
      attrs: {
        expanded: "",
        label: "Custo",
        placeholder: "R$",
        "type-input": "money"
      },
      model: {
        value: item.price,
        callback: function callback($$v) {
          _vm.$set(item, "price", $$v);
        },
        expression: "item.price"
      }
    }), _vm._v(" "), _c("b-button", {
      staticClass: "remove-item",
      attrs: {
        "icon-right": "delete"
      },
      on: {
        click: function click($event) {
          return _vm.confirmDelete("cost", item, idx);
        }
      }
    })], 1);
  })], 2) : _vm._e(), _vm._v(" "), _vm.idService ? _c("b-field", {
    attrs: {
      label: "Range de venda"
    }
  }) : _vm._e(), _vm._v(" "), _vm.idService ? _c("div", {
    staticClass: "list-rates-requests"
  }, [_c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewLineSale
    }
  }), _vm._v(" "), _vm._l(_vm.listRangesSales, function (item, idx) {
    return _c("b-field", {
      key: idx,
      attrs: {
        grouped: ""
      }
    }, [_c("app-input", {
      attrs: {
        expanded: "",
        label: "De",
        mask: ["#", "##", "###", "####", "#####", "######", "#######", "########", "#########", "##########", "###########", "############"],
        placeholder: "0",
        disabled: ""
      },
      model: {
        value: item.initialConsumption,
        callback: function callback($$v) {
          _vm.$set(item, "initialConsumption", $$v);
        },
        expression: "item.initialConsumption"
      }
    }), _vm._v(" "), _c("app-input", {
      attrs: {
        expanded: "",
        label: "Até",
        mask: ["#", "##", "###", "####", "#####", "######", "#######", "########", "#########", "##########", "###########", "############"],
        placeholder: "0"
      },
      model: {
        value: item.finalConsumption,
        callback: function callback($$v) {
          _vm.$set(item, "finalConsumption", $$v);
        },
        expression: "item.finalConsumption"
      }
    }), _vm._v(" "), _c("app-input", {
      attrs: {
        expanded: "",
        label: "Custo",
        placeholder: "R$",
        "type-input": "money"
      },
      model: {
        value: item.price,
        callback: function callback($$v) {
          _vm.$set(item, "price", $$v);
        },
        expression: "item.price"
      }
    }), _vm._v(" "), _c("b-button", {
      staticClass: "remove-item",
      attrs: {
        "icon-right": "delete"
      },
      on: {
        click: function click($event) {
          return _vm.confirmDelete("sales", item, idx);
        }
      }
    })], 1);
  })], 2) : _vm._e(), _vm._v(" "), _c("b-field", {
    staticClass: "field-buttons"
  }, [_c("b-button", {
    staticClass: "btn-save-button",
    attrs: {
      "native-type": "submit"
    }
  }, [_vm._v("\n        Salvar serviço\n      ")])], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;