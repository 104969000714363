"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "columns cols-cards-info is-multiline"
  }, [_c("div", {
    staticClass: "column is-one-two"
  }, [_c("div", {
    staticClass: "card card-dashboard-monitor-component p-2 pl-2 pt-4"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_vm.isLoadingChartIP ? _c("b-skeleton", {
    attrs: {
      height: "300px"
    }
  }) : _c("bar-chart", {
    attrs: {
      "chart-data": _vm.dataChartIPs,
      horizontal: true,
      height: 300
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "column is-one-two"
  }, [_c("div", {
    staticClass: "card card-dashboard-monitor-component p-2 pl-2 pt-4"
  }, [_vm._m(1), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_vm.isLoadingChartErrors ? _c("b-skeleton", {
    attrs: {
      height: "300px"
    }
  }) : _c("bar-chart", {
    attrs: {
      "chart-data": _vm.chartDataErrors,
      horizontal: true,
      height: 300
    }
  })], 1)])])]), _vm._v(" "), _c("div", {
    staticClass: "columns cols-cards-info is-multiline"
  }, [_c("div", {
    staticClass: "column is-one-two"
  }, [_c("div", {
    staticClass: "card card-dashboard-monitor-component p-2 pl-2 pt-4"
  }, [_vm._m(2), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_vm.isLoadingChartPath ? _c("b-skeleton", {
    attrs: {
      height: "300px"
    }
  }) : _c("bar-chart", {
    attrs: {
      "chart-data": _vm.chartDataPaths,
      horizontal: true,
      height: 300
    }
  })], 1)])])]), _vm._v(" "), _c("div", {
    staticClass: "card card-api-request-component p-4 mb-4"
  }, [_vm._m(3), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_vm.rows.length == 0 && !_vm.isLoadingListErrors ? _c("h1", [_c("img", {
    attrs: {
      src: require("@/assets/images/icon-checked-grenn.png")
    }
  }), _vm._v(" Sem requisições até o momento\n      ")]) : _c("b-table", {
    ref: "multiSortTable",
    attrs: {
      data: _vm.rows,
      paginated: "",
      "backend-pagination": "",
      total: _vm.total,
      "per-page": _vm.perPage,
      "sort-icon": _vm.sortIcon,
      "sort-icon-size": _vm.sortIconSize,
      loading: _vm.isLoadingListErrors,
      "aria-next-label": "Next page",
      "aria-previous-label": "Previous page",
      "aria-page-label": "Page",
      "aria-current-label": "Current page"
    },
    on: {
      "page-change": _vm.onPageChange
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "ip",
      label: "IP"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.client_ip) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "service",
      label: "Serviço"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.service) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "requestURI",
      label: "URI"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.requestURI) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "method",
      label: "Método",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.method) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "http_status",
      label: "Status",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("small", {
          staticClass: "type-call error",
          attrs: {
            title: props.row.http_status
          }
        }, [_vm._v(_vm._s(props.row.http_status))])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "horas",
      label: "Horas",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.hour) + "\n          ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "totalErrors",
      label: "Total de Erros",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.totalErrors) + "\n          ")];
      }
    }])
  })]], 2)], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "col-detail-header"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("Top IP's ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "col-detail-header"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("Top HTTP code")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "col-detail-header"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("Top URI's")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "col-detail-header"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("\n          Lista de Erros\n        ")])])]);
}];
render._withStripped = true;