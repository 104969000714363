"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = [{
  title: 'Dashboard',
  path: '/dashboard-list',
  icon: 'eva eva-pie-chart-outline',
  roles: [1, 2, 3],
  clients: []
}, {
  title: 'Monitor de Conexões',
  path: '/connection-monitor',
  icon: 'eva eva-activity-outline',
  roles: [1, 2, 3],
  clients: []
}, {
  title: 'Serviços Ativos',
  path: '/products-services-list',
  icon: 'eva eva-cube-outline',
  roles: [1, 2, 3, 4],
  clients: []
}, {
  title: 'Clientes',
  path: '/clients-list',
  icon: 'eva eva-grid-outline',
  roles: [1],
  clients: []
}, {
  title: 'Usuários',
  path: '/users-list',
  icon: 'eva eva-people-outline',
  roles: [1],
  clients: []
}, {
  title: 'Consulta pessoal',
  path: '/personal-consultation-list',
  icon: 'eva eva-file-text-outline',
  roles: [1, 2, 7],
  clients: ['DICRE DFE', 'Consulta Análise Pessoal', 'Brasilprev']
}, {
  title: 'Protocolos suspeitos',
  path: '/suspicious-protocols/list',
  icon: 'eva eva-search-outline',
  roles: [1, 2],
  clients: ['DICRE DFE']
}, {
  title: 'Serviços disponíveis',
  path: '/services-list',
  icon: 'eva eva-shopping-cart-outline',
  roles: [1, 2, 3],
  clients: []
}, {
  title: 'Tipo de Serviços',
  path: '/services-types-list',
  icon: 'eva eva-inbox-outline',
  roles: [1, 3],
  clients: []
}, {
  title: 'Financeiro',
  path: '/monitoring-report',
  icon: 'eva eva-file-text-outline',
  roles: [1, 3, 4],
  clients: []
}, {
  title: 'Onboarding',
  path: '/onboarding/list-business-unit',
  icon: 'eva eva-lock-outline',
  roles: [1, 2, 9],
  clients: ['CBTU']
}, {
  title: 'Consignado',
  path: '/payroll/loans',
  icon: 'eva eva-shuffle-2-outline',
  roles: [1],
  clients: []
}, {
  title: 'Risk Device',
  path: '/riskdevice',
  icon: 'eva eva-options 2 outline',
  roles: [1],
  clients: []
}, {
  title: 'Prova de Vida',
  path: '/liveness-hive/list',
  icon: 'eva eva-checkmark-square-outline',
  roles: [1, 10],
  clients: []
}, {
  title: 'Suporte',
  path: '/support',
  icon: 'eva eva-message-circle-outline',
  roles: [1, 2, 7, 8, 10, 11, 12],
  clients: []
}, {
  title: 'Processamento em Lote',
  path: '/batch-process/all-batch-process',
  icon: 'eva eva-layers-outline',
  roles: [1],
  clients: []
}, {
  title: 'Auditoria',
  path: '/audit/clients',
  icon: 'eva eva-clipboard-outline',
  roles: [1],
  clients: []
}];